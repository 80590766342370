import Service from "../Service";

const resource = "frzdamageflawcultive/";

export default {
    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    list(obj, requestID) {
        // alert("kkjj")
        return Service.post(resource + "list", obj, {
            params: { requestID: requestID },
        });
    },

};