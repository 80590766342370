<template>
	<div>
		<s-crud
			title="Daños Defectos Cultivo"
			:config="config"
			:filter="filter"
			add
			@save="save($event)"
			edit
			remove>
			
			<template v-slot="props">
				<v-container>
					<v-row>
						<v-col cols="4" lg="4" md="6" sm="12">
							<s-select-definition
								label="Tipo Cultivo"
								v-model="props.item.TypeCultive"
								:def="1173"
							></s-select-definition>
						</v-col>
						<v-col cols="4" lg="4" md="6" sm="12">
							<s-text
								label="Nombre del daño"
								v-model="props.item.DfcName"
								ref="textDfcName"
							></s-text>
						</v-col>
						<v-col cols="4" lg="4" md="6" sm="12">
							<s-text
								label="Abreviación del daño"
								v-model="props.item.DfcAbbreviation"
								ref="textDfcAbbreviation"
							></s-text>
						</v-col>
					</v-row>
					<v-row>
						<!-- <v-col cols="4" lg="4" md="6" sm="12">
							<s-select-definition
								label="Tipo Cultivo"
								v-model="props.item.TypeCultive"
								:def="1173"
							></s-select-definition>
						</v-col> -->
						<v-col cols="4" lg="4" md="6" sm="12">
							<s-text
								label="Tolerancia"
								v-model="props.item.DfcTolerance"
								ref="textDfcTolerance"
								number
							></s-text>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<template v-slot:filter>
				<v-container>
					<v-row>
						<v-col>
							<s-select-definition
								label="Tipo Cultivo"
								v-model="filter.TypeCultive"
								:def="1173"
							></s-select-definition>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</s-crud>
	</div>
</template>

<script>
	import _sDamageFlawCultiveService from "@/services/FrozenProduction/DamageFlawCultiveService.js"

	export default {
		data() {
			return {
				config: {
					model: {
						DfcID: "ID"
					},
					service: _sDamageFlawCultiveService,
					headers: [
						{text: "ID", value: "DfcID"},
						{text: "Tipo de Cultivo", value: "TypeCultiveName"},
						{text: "Daño", value: "DfcName"},
						{text: "Abreviación", value: "DfcAbbreviation"},
						{text: "Tolerancia", value: "DfcTolerance"},
					]
				},
				filter: { TypeCultive: 0}
			}
		},

		methods: {
			save(item) {
				if(item.DfcName == "")
				{
					this.$fun.alert("Ingrese el nombre", "warning");
					this.$refs.focus("textDfcName");
					return;
				}

				item.save();
			}
		},
	}
</script>